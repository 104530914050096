document.addEventListener(
  "DOMContentLoaded",
  () => {
    const lang =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage;
    setTimeout(() => {
      let h = "en";
      if (lang.match(/ja/) || lang.match(/jp/)) {
        h = "jp";
      }
      location.href = "/" + h + "/";
    }, 1500);
  },
  false
);
